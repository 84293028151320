<div class="background-color-container">
  <div class="background-image-container">
    <h1>Contact</h1>
    <form (ngSubmit)="onSubmit()" name="leadForm">
      <input type="text" name="name" id="" placeholder="Name" [(ngModel)]="model.name" required/>
      <input type="email" name="emal" id="email" placeholder="Email" [(ngModel)]="model.email" required/>
      <input type="tel" id="phone" name="phone" placeholder="Phone Number" [(ngModel)]="model.phone" required/>
      <textarea class="border"
                id="Message"
                placeholder="Message"
                name="messageBody"
                [(ngModel)]="model.messageBody"></textarea>
      <input type="submit" class="submit" value="Submit"/>
    </form>
    <h2>Steve Bilyea</h2>
    <h2>517-270-3211</h2>
    <h2>Richard Beach</h2>
    <h2>517-270-4704</h2>
    <h5>Adrian,MI</h5>
  </div>
</div>
