import { Injectable } from '@angular/core';
import { getBaseUrl } from "../../main";
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Lead } from "../models/lead.model";

@Injectable({
  providedIn: 'root'
})
export class LeadService {

  constructor(private http: HttpClient) { }

  createLead(lead: Lead) {
    this.http.post(getBaseUrl() + "api/lead", lead).subscribe();
  }
}
