import { Component, OnInit } from '@angular/core';
import { Lead } from "../../models/lead.model";
import { LeadService } from "../../services/lead.service";

@Component({
  selector: 'contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {

  model: Lead;

  constructor(private leadService: LeadService) {
    this.model = new Lead();
  }

  ngOnInit(): void {
  }

  onSubmit() {
    this.leadService.createLead(this.model);
    alert("Your message has been sent. Thank you.")
  }
}
