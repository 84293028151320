<div class="exp-container">
  <h3>Licensed And Insured</h3>
  <h3>With Over 20 Years of experience</h3>
  <div class="h4-container">
    <h4>
      Mobile Home Services is a licensed and insured LLC. that specializes in
      extensive mobile home repairs. The Owners and Professionals on our team
      have over 20 years of experience, talent and integrity in this field! The
      owners are <span class="orange">Michigan local businessmen</span>, who are
      passionate about creating a home you enjoy.
    </h4>
  </div>

  <div class="divider"></div>
  <h3>24/7 Emergency Service</h3>
  <h3>+</h3>
  <h3>Free Estimates</h3>
  <div class="h4-container">
    <h4>
      Our mission is to provide a wide variety of quality mobile home services
      at financially suitable prices, that fufills each of our clients needs. We
      know that time is valuble and hard to come by, so we make it a priority to
      <span class="orange">adjust to your schedule</span>.
    </h4>
  </div>

  <div class="card-container">
    <!-- Electrical -->
    <div class="block">
      <img src="../../../assets/electricityIcon.png" />
      <h3>Electrical</h3>
      <h5>
        From blown breakers to dead outlets, electrical issues are bound to
        happen at some point in your home. Whether it is fixing an issue or
        updating the wiring, we can ensure quality work.
      </h5>
    </div>

    <!-- Skirting -->
    <div class="block">
      <img src="../../../assets/mobileHome.png" />
      <h3>Skirting</h3>
      <h5>
        Fixing or adding skirting around your home not only increases the value
        but acts as a protective barrier against the outside world. It also
        helps to keep snow and water from getting underneath the house. This in
        turn, naturally acts as an insulator.
      </h5>
    </div>

    <!-- Winterization -->
    <div class="block">
      <img src="../../../assets/winterization.png" />
      <h3>Winterization</h3>
      <h5>
        Insulation is only a small portion of the process. Sealing metal roofs
        and adding storm windows can also play a crucial role in keeping the
        heating bill down.
      </h5>
    </div>
  </div>
</div>
