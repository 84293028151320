<div class="outer">
  <div class="inner">
    <img src="../../../assets/Mobile_Home_Home_Page 2 1.png" alt="Mobile Home Services">
    <h6>Adrian, MI</h6>
    <h1>Providing The <span class="orange">Highest Quality</span> <span class="same-line">Mobile Home</span> Service & Repairs</h1>
    <h3 class="orange">Our Services</h3>
    <ul>
      <li class="first">Electrical</li>
      <li>Windows</li>
      <li>Siding Repair</li>
      <li>Floor Repair</li>
      <li>Painting</li>
      <li>Screens</li>
      <li>Belly Repair</li>
      <li>Water Heaters</li>
      <li>Small Furnace Repair</li>
      <li>Skirting</li>
      <li>Plumbing</li>
      <li>Doors</li>
      <li>Winterization</li>
      <li>Porches</li>
      <li>Skylights</li>
      <li>Heat Tape</li>
      <li>Remodels</li>
      <li>Water & Sewer Lines</li>
    </ul>
  </div>
</div>
